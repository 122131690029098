<template>
  <div>
    <v-app-bar id="home-app-bar" app color="white" elevation="1" height="80">
      <!-- width should be 18, but this logo sucks -->
      <base-img
        :src="require('@/assets/logo-tdf.jpg')"
        class="mr-3 hidden-xs-only"
        contain
        max-width="280"
        width="100%"
      />

      <v-spacer />

      <div>
        <!--        <v-tabs v-model="tab" :change="selected()">-->
        <!--          <v-tab-->
        <!--            v-for="menu in items"-->
        <!--            :key="menu.text"-->
        <!--            :ripple="false"-->
        <!--            active-class="text_primary"-->
        <!--          >-->
        <!--            {{ menu.text }}-->
        <!--          </v-tab>-->
        <!--        </v-tabs>-->
        <v-tabs class="hidden-sm-and-down" optional v-model="tab">
          <v-tab
            v-for="(menu, i) in items"
            :key="i"
            :to="{ name: menu.page }"
            :exact="true"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ menu.text }}
          </v-tab>
        </v-tabs>
      </div>

      <v-btn
        v-if="items.length > 0"
        class="hidden-md-and-up"
        @click="drawer = !drawer"
        fab
        small
        dark
        color="primary"
      >
        <font-awesome-icon icon="bars" />
      </v-btn>

      <!--      <v-app-bar-nav-icon-->
      <!--        v-if="items.length > 0"-->
      <!--        class="hidden-md-and-up"-->
      <!--        @click="drawer = !drawer"-->
      <!--      />-->
    </v-app-bar>

    <home-drawer v-model="drawer" :items="items" />
  </div>
</template>

<script>
export default {
  name: "HomeAppBar",

  components: {
    HomeDrawer: () => import("./Drawer")
  },

  // mounted() {
  //   this.tab = this.items[0].page;
  // },

  methods: {
    selected: function() {
      console.log(this.tag);
    }
  },
  data: () => ({
    drawer: null,
    tab: 0
  }),

  props: {
    items: Array
  }
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

  .v-tab
    &::before
      display: none
</style>
